<template>
  <div class="mui-content">
    <div class="product-body" :style="`height: ${height}px;`" v-if="orderInfo">
      <div class="order-reminder" v-if="orderReminder">
        <img src="http://freesaasofficedata.oss.aliyuncs.com/miShop/20201125161405322.png" />
        <div class="ivv-txt">{{ orderReminder }}</div>
      </div>
      <div class="mui-table-view address">
        <div class="point"><span class="icon-location"></span></div>
        <div class="info">
          <span>收货人: {{ orderInfo.name }}</span>
          <span class="mi-pull-right">{{ orderInfo.phone }}</span>
        </div>
        <div class="detail">
          <span>收货地址: {{ orderInfo.fullAddress }}</span>
        </div>
      </div>

      <div class="product-list">
        <div class="brand-box" style="border-bottom: 1px solid #eee;">
          <img :src="brandId === 1 ? imageXM : (brandId === 3 ? imageFJS : imageMW)" />
          <label>{{ brandId === 4 ? '大健康' : '美妆' }}</label>
        </div>
        <template v-for="(item, index) in orderInfo.goodsList">
          <product-item :data-item="item" :key="index" color="#FFB5BB" :width="125" :effect="false" :stock="false" :stepper="false" :count="true" />
        </template>
        <van-cell-group class="order-info">
          <van-cell>
            <van-row>
              <van-col class="subtitle" span="8">兑换商品金额：</van-col>
              <van-col span="16" align="right" class="font-pink">￥{{ orderInfo.goodsTotalAmount }}</van-col>
            </van-row>
            <van-row>
              <van-col class="subtitle" span="8">兑换券使用金额：</van-col>
              <van-col span="16" align="right" class="font-pink">￥{{ orderInfo.useCouponsAmount }}</van-col>
            </van-row>
            <van-row>
              <van-col class="subtitle" span="8">补差金额</van-col>
              <van-col span="16" align="right" class="font-pink">￥{{ orderInfo.bcAmount }}</van-col>
            </van-row>
            <van-row>
              <van-col class="subtitle" span="8">运费</van-col>
              <van-col span="16" align="right" class="font-pink">￥{{ orderInfo.postage }}</van-col>
            </van-row>
          </van-cell>
          <!-- <van-cell>
            <van-row v-if="orderInfo.redBalance">
              <van-col class="subtitle" span="12">预存红包抵扣：</van-col>
              <van-col span="12" align="right" class="font-pink">￥{{ orderInfo.redBalance }}
                <van-icon name="question-o" @click="showPrestoreLayer = true" />
              </van-col>
            </van-row>
            <van-row>
              <van-col class="subtitle" span="12">采购券抵扣：</van-col>
              <van-col span="12" align="right" class="font-pink">共￥{{ orderInfo.mixedAmount }}可用</van-col>
            </van-row>
            <van-row>
              <van-col class="subtitle" span="12">{{ brandId == 4 ? '大健康' : '美妆' }}账户余额抵扣：</van-col>
              <van-col span="12" align="right" class="font-pink">共￥{{ orderInfo.accountBalance }}可用</van-col>
            </van-row>
          </van-cell> -->
          <!-- <van-cell>
            <van-row>
              <van-col class="subtitle" span="8">优先抵扣金额：</van-col>
              <van-col span="16" align="right" class="pay-check-list">
                <van-radio-group v-model="checked" direction="horizontal" checked-color="#FF98A0">
                  <van-radio :name="1" :disabled="orderInfo.disAccount">采购券金额</van-radio>
                  <van-radio :name="2" :disabled="orderInfo.disAccount">账户余额</van-radio>
                  <van-radio :name="0">都不使用</van-radio>
                </van-radio-group>
              </van-col>
            </van-row>
          </van-cell> -->
        </van-cell-group>
      </div>
      <van-popup v-model="isPayByPwd" round :close-on-click-overlay="false">
        <password :show="isPayByPwd" :payAmount="orderInfo.payAmount" :accountBalance="orderInfo.accountBalance" :mixedBalance="orderInfo.mixedAmount" @handletap="hanldePwdClick" />
      </van-popup>
    </div>
    <van-popup v-model="showPrestoreLayer" round>
      <prestore :phone="orderInfo ? orderInfo.phone : ''" @success="findSuccess" @cancel="showPrestoreLayer = false" />
    </van-popup>
    <div class="footer" v-if="orderInfo">
      <van-row class="mui-bar-tab" type="flex" justify="end">
        <van-col span="14">
          <span class="count">共{{ orderInfo.Total }}件</span>
          <span class="txt">待付款:</span>
          <span class='price font-pink'>¥{{ orderInfo.payAmount }}</span>
        </van-col>
        <van-col span="8">
          <van-button type="default" @click="toPay">提交订单</van-button>
        </van-col>
      </van-row>
    </div>
  </div>
</template>

<script>
import brandFjs from "../../../assets/images/brand-3.png";
import brandXk from "../../../assets/images/brand-1.png";
import brandMw from "../../../assets/images/brand-4.png"
import ProductItem from "../../../components/Product/Index"
import password from "../../../components/Order/Password.vue"
import Prestore from "../../../components/FindPreStore.vue"
export default {
  name: "Confirm",
  components: { ProductItem, password, Prestore },
  data() {
    return {
      goodList: '', //下单的商品
      addressInfo: null,
      orderInfo: null,
      buyerMessage: '', //买家留言
      payList: [{ name: "在线支付", id: 1 }],
      orderReminder: '', //下单提醒
      isRepeat: false,
      height: '',
      brandId: 3,
      checked: 0,
      imageFJS: brandFjs,
      imageXM: brandXk,
      imageMW: brandMw,
      isPayByPwd: false,
      showPrestoreLayer: false
    }
  },
  created() {
    let { goods, brandId } = this.$route.query;
    this.goodList = goods;
    this.brandId = +brandId;
    this.getShopCartList(goods);
    this.height = document.documentElement.clientHeight
  },
  methods: {
    getShopCartList: function () {
      this.$toast.loading({ message: '加载中...', duration: 0, overlay: true });
      this.post("/OrderGoods/RetailZone/ConfirmRetailZoneOrder", {
        goodsList: this.getParams(),
        brandId: this.brandId,
        v: 1
      }, true, 2).then(res => {
        this.$toast.clear();
        if (res.code === 1) {
          let data = res.response;
          if (!data.fullAddress) {
            return this.$dialog.alert({ message: '蜜蛋小姐姐提示您，还未填写地址,点击前往', title: '提示' }).then(() => {
              window.location.href = this.baseHost + "/7999/Arongleweb/html/groupon/address_edit.html?orderType=11&brandId=" + this.brandId + "&param=" + this.goodList;
            });
          }
          let total = 0;
          data.goodsList.forEach(it => {
            total += it.goodsCount;
            it.goodsUrl = it.goodsImg;
            it.actEffect = it.goodsActEffect;
            it.specifications = it.goodsSpecifications;
          })
          data.Total = total;
          // if (data.redBalance > 0) {
          //   let amount = data.redBalance - data.payAmount;
          //   data.payAmount = amount > 0 ? 0 : -amount
          // }
          // if (data.mixedAmount >= data.payAmount) this.checked = 1;
          // else if (data.accountBalance >= data.payAmount) this.checked = 2;
          // else if (data.mixedAmount + data.accountBalance > data.payAmount) this.checked = 1;
          // else data.disAccount = true;
          this.orderInfo = data;
        }
      });

    },
    toPay() {
      this.$toast.loading({ message: '订单生成中...', duration: 0, overlay: true });
      this.post("/OrderGoods/RetailZone/CreateRetailZoneOrder", {
        v: 1,
        goodsList: this.getParams(),
        sourceType: 2,
        brandId: this.brandId,
        payType: this.checked,
        remark: this.buyerMessage
      }, true, 2).then(res => {
        this.$toast.clear();
        if (res.code === 1) {
          let { orderId, orderNum, paySuccess } = res.response;
          window.localStorage.setItem("retailCartList", '');
          if (paySuccess) {
            this.$router.replace(`/retailZone/success/${orderId}?orderNum=${orderNum}&brandId=${this.brandId}`)
          } else if (this.checked === 0) {
            this.$dialog.confirm({ title: "提示", message: '下单成功!', confirmButtonText: '去支付', cancelButtonText: '继续下单' }).then(() => {
              this.$router.replace("/Recharge/order_detail?orderId=" + orderId + "&orderType=14")
            }).catch(() => {
              this.$router.back();
            })
          } else {
            this.orderInfo.orderId = orderId;
            this.orderInfo.orderNum = orderNum;
            this.isPayByPwd = true;
          }
        } else {
          this.$dialog({
            title: '提示',
            message: res.msg,
            confirmButtonColor: '#ff98a0'
          })
        }
      });
    },
    getParams() {
      let arr = decodeURIComponent(this.goodList).split("#"), goodList = [];
      arr.forEach(it => {
        let good = it.split("_");
        goodList.push({ goodsId: good[0], goodsCount: good[1] })
      });
      return JSON.stringify(goodList);
    },
    //预存红包找回成功
    findSuccess(amount) {
      this.orderInfo.redBalance = amount;
    },
    hanldePwdClick({ type, password }) {
      let { orderId, orderNum } = this.orderInfo;
      if (type === 0) {
        this.isPayByPwd = false;
        return this.$router.replace("/Recharge/order_detail?orderId=" + orderId + "&orderType=14")
      }
      this.accountPay(password, orderId, orderNum)
    },
    //账户余额或者采购券支付
    accountPay(password, orderId, orderNum) {
      this.post("/OrderGoods/PreferentialZone/PayAccountBalanceOrder", {
        orderId: orderId,
        depayType: this.checked === 1 ? 1 : 0,
        password: password
      }, true, 2).then(res => {
        this.$toast.clear();
        if (res.code === 1) {
          this.isPayByPwd = false;
          this.$router.replace(`/retail/success/${orderId}?orderNum=${orderNum}&brandId=${this.brandId}`)
        } else {
          this.$dialog.alert({ title: '错误', message: res.msg }).then(() => {
            // this.$router.replace("/Recharge/order_detail?orderId=" + orderId + "&orderType=14")
          })
        }
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.product-body {
  padding: 11px 15px 84px;
  text-align: left;
  background-color: #f8f8f8;
  box-sizing: border-box;
  overflow-y: scroll;

  .order-reminder {
    display: flex;
    margin: -11px -15px 5px;
    padding: 11px 15px;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    align-items: center;
    background-color: #fff;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);

    img {
      width: 43px;
      margin-right: 12px;
    }

    .ivv-txt {
      color: #ff98a0;
    }
  }

  .address {
    position: relative;
    border-radius: 6px;
    padding: 17px 15px 17px 58px;
    background-color: #fff;

    .point {
      position: absolute;
      top: 50%;
      left: 11px;
      width: 33px;
      height: 33px;
      text-align: center;
      margin-top: -16px;
      border-radius: 50%;
      background-color: #ffb5bb;

      .icon-location {
        color: #fff;
        font-size: 20px;
        line-height: 33px;

        &:before {
          content: '\e9f0';
        }
      }
    }

    .info {
      span {
        color: #666;
      }

      .mi-pull-right {
        float: right;
      }
    }

    .detail {
      padding-top: 3px;

      span {
        color: #999;
      }
    }
  }

  .product-list {
    margin-top: 10px;

    .brand-box {
      display: flex;
      align-items: center;
      padding: 11px 15px;
      border-radius: 6px 6px 0 0;
      background-color: #fff;

      img {
        width: 40px;
      }

      label {
        color: #666;
        font-size: 14px;
      }
    }

    .good-item {
      width: 100%;
      display: flex;
      padding: 10px 15px;
      position: relative;
      background-color: #fff;
      box-sizing: border-box;

      /deep/.price-box {
        color: #ffb5bb;
      }

      &:after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 30px;
        background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAIAAAA9AgMAAAD69FDPAAAACVBMVEUAAAAAAAAAAACDY+nAAAAAA3RSTlMIAQTqE2A+AAAAGklEQVQI12MIIAImMExgWADCeGADECowkAAAXQURodU+YFsAAAAASUVORK5CYII=')
          repeat-x;
        background-size: auto 100%;
      }

      &:last-child {
        border-radius: 0 0 6px 6px;
      }
    }

    .van-cell-group {
      overflow: hidden;
      margin-top: 10px;
      border-radius: 6px;

      .subtitle {
        color: #666666;
        font-size: 12px;
      }

      .van-cell {
        font-size: 12px;

        .van-cell__title {
          > span {
            color: #666;
          }
        }
      }

      .pay-check-list {
        .van-radio-group {
          justify-content: space-between;

          .van-radio {
            margin-right: 0;
            margin-bottom: 6px;
          }
        }
      }

      .reCommend {
        position: relative;
        padding: 0 15px 10px;

        .van-cell {
          padding: 10px 0;

          .van-cell__value {
            span {
              color: #666;
            }
          }

          &:after {
            border-width: 0;
          }
        }

        .van-swipe-box {
          position: absolute;
          top: 8px;
          right: 0;

          .van-switch--on {
            background-color: #ff98a0;
          }

          .van-switch__node {
            font-size: 24px;
          }

          .board-txt {
            display: inline-block;
            vertical-align: top;
          }
        }

        //.van-switch {
        //  position: absolute;
        //  top: 0;
        //  right: 0;
        //  .van-switch__node {
        //    font-size: 22px;
        //  }
        //}
        .van-loading {
          line-height: 110px;
          display: inline-block;
        }

        .agent-info {
          height: 110px;
          padding: 10px 15px;
          text-align: center;
          box-sizing: border-box;
          background-color: #f8f8f8;

          .mui-input-row {
            padding: 3px 0;
            text-align: left;

            label {
              color: #999;
              width: 65px;
              display: inline-block;
            }

            .value {
              color: #666;
            }
          }
        }
      }
    }

    .order-info {
      margin-top: 10px;

      .van-cell {
        .van-cell__title {
          span {
            color: #666;
          }
        }

        &.order-prompt {
          .van-cell__value {
            color: #ff98a0;
          }
        }

        &:after {
          border-color: #f1f1f1;
          transform: scaleY(1);
          -webkit-transform: scaleY(1);
        }
      }
    }
  }

  &.pay-box-fixed {
    .product-list {
      padding-top: 44px;
    }
  }
}

.mui-content {
  /*padding-bottom: 84px;*/

  .footer {
    .mui-bar-tab {
      position: fixed;
      left: 0;
      right: 0;
      bottom: 0;
      height: 44px;
      z-index: 100;
      padding: 0 15px;
      border-top: 1px solid #f1f1f1;
      background-color: #fff;

      .van-col {
        display: flex;
        font-size: 12px;
        align-items: center;
        letter-spacing: 1px;
        justify-content: flex-end;

        .count {
          color: #999999;
          padding-right: 5px;
        }

        .txt {
          color: #999999;
          font-size: 14px;
        }

        .price {
          font-size: 18px;
          margin-top: 5px;
        }

        .van-button {
          height: 33px;
          color: #fff;
          padding: 0 18px;

          line-height: 30px;
          border-radius: 33px;
          background-color: #ff98a0;

          span {
            line-height: 31px;
          }

          &.van-button--disabled {
            opacity: 1;
            background-color: #ccc;
          }
        }
      }
    }
  }

  .van-checkbox__icon--checked {
    .van-icon {
      border-color: #ff98a0;
      background-color: #ff98a0;
    }
  }

  .font-pink {
    color: #ff98a0;
  }

  .mui-ellipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.iphoneX {
  .mui-content {
    /*padding-bottom: 100px;*/

    .footer {
      .mui-bar-tab {
        padding-bottom: 30px;
      }
    }
  }
}

.van-button {
  &:active:before {
    opacity: 0;
  }
}

.icon--17:before {
  content: '\e9b4';
}

.icon--4:before {
  content: '\e9c5';
}

@media screen and (max-height: 500px) {
  .mui-backNone .rop-btn {
    padding-top: 30%;
  }
}

@media screen and (max-width: 360px) {
  .self-cont .self-prod li .prod-cont .mui-numbox {
    width: 90px;
    padding: 0 28px;
  }

  .self-cont .self-prod li .prod-cont .mui-btn {
    width: 28px;
  }
}
</style>